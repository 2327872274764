import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ChatbotWrapper from './ChatbotWrapper';
import AgentWrapper from '../Agent/AgentWrapper';

window.renderChatbot = (containerId, props) => {
  console.log('renderChatbot called with:', containerId, props);
  const container = document.getElementById(containerId);
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <ChatbotWrapper {...props} />
    </BrowserRouter>
  );
};

window.renderAgent = (containerId, props) => {
  console.log('renderAgent called with:', containerId, props);
  const container = document.getElementById(containerId);
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <AgentWrapper {...props} />
    </BrowserRouter>
  );
};

// Enable HMR for ChatbotWrapper
if (module.hot) {
  module.hot.accept('./ChatbotWrapper', () => {
    const container = document.getElementById(containerId);
    const root = createRoot(container);
    root.render(
      <BrowserRouter>
        <ChatbotWrapper {...props} />
      </BrowserRouter>
    );
  });

  // Enable HMR for AgentWrapper
  module.hot.accept('../Agent/AgentWrapper', () => {
    const container = document.getElementById(containerId);
    const root = createRoot(container);
    root.render(
      <BrowserRouter>
        <AgentWrapper {...props} />
      </BrowserRouter>
    );
  });
}






