import React from 'react';

const ChatMessages = ({ thisAgent, messages, messagesEndRef, parseTextToJSX, onImageClick }) => {
  return (
    <div className="chat-messages" ref={messagesEndRef} style={{ paddingTop: '2vh' }}>
      {[
        {
          sender: 'received',
          text: `您好，我是 { ${thisAgent?.agentName} }，歡迎與我對話！`
        },
        ...messages
      ].map((messageItem, index) => {
        if (messageItem.sender === 'loading') {
          return (
            <div key={`${index}${messageItem.id}`} className="dialog-row received-side">
              <div className="dialog-box">
                <div className="loading-dots">
                  <div className="dot" style={{ '--dot-index': 0 }}></div>
                  <div className="dot" style={{ '--dot-index': 1 }}></div>
                  <div className="dot" style={{ '--dot-index': 2 }}></div>
                </div>
              </div>
            </div>
          );
        }
        return (
          <div key={`${index}${messageItem.id}`} className={`dialog-row ${messageItem.sender}-side`}>
            {messageItem.sender === "sent" && <div className="timer-sent">{messageItem.time}</div>}
            <div className="dialog-box">
              {messageItem.text && <div className="title">{parseTextToJSX(messageItem.text)}</div>}
              {messageItem.imageUrl && (
                <img
                  src={messageItem.imageUrl}
                  alt="Chatbot response"
                  className="chat-image"
                  style={{ marginTop: '2vh', cursor: 'pointer' }}
                  onClick={() => onImageClick(messageItem.imageUrl, messageItem.text)}
                />
              )}
            </div>
            {messageItem.sender === "received" && <div className="timer-received">{messageItem.time}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default ChatMessages;