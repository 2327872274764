// src/utils/axiosInstance.js
import axios from 'axios';
// API_ENDPOINT in index.js
import { BASE_URL } from ".";

import { refreshToken } from 'utils/authUtils';

// Common Axios configuration
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000, // Default timeout of 20 seconds
});



// Request Interceptor for adding tokens or other headers
axiosInstance.interceptors.request.use(config => {

  // Check if the user has been redirected to login
  const isRedirectedToLogin = sessionStorage.getItem("isRedirectedToLogin");

  // Define login and authentication-related URLs
  const resetPasswordRegex = /\/reset-password\/[^\/]+$/;
  const skipTokenUrls = [
    '/register', 
    '/login', 
    '/google-login', 
    '/verify-account', 
    '/resend-verification',
    '/forgot-password', 
    '/conversations',
    '/call-service'
  ];
  const requiresToken = !skipTokenUrls.some(url => config.url.includes(url)) &&
                        !resetPasswordRegex.test(config.url);


  /*---- Endpoints that require longer timeouts ----*/

  // translate: [projectTranslate.js]
  // transbyte & transsimple: [Dashboard-Translate]
  const longTimeoutEndpoints = ['/transbyte', '/transsimple', '/login'];
  const longLongTimeoutEndpoints = [
    '/trans-snapdragon',
    '/trans-gpt',
    '/conversations', 
    '/projects', 
    '/image', 
    '/get-qapairs',
    '/customer-newjson',
    '/customer-responsetype',
    '/processGenerate',
    '/file',
    '/chatbotsConversations',
    '/generateSubknowledge',
  ];
  const longLongLongTimeoutEndpoints = ['search-database', '/translate', '/db'];
  const longTimeoutDuration = 30000; // Longer timeout duration
  const longLongTimeoutDuration = 90000; // Very long timeout duration
  const longLongLongTimeoutDuration = 180000; // Very Very long timeout duration

  // Adjust timeout for specific endpoints
  if (longTimeoutEndpoints.some(url => config.url.includes(url))) {
    config.timeout = longTimeoutDuration; // Set a longer timeout
  } else if (longLongTimeoutEndpoints.some(url => config.url.includes(url))) {
    config.timeout = longLongTimeoutDuration; // Set a very long timeout
  } else if (longLongLongTimeoutEndpoints.some(url => config.url.includes(url))) {
    config.timeout = longLongLongTimeoutDuration; // Set very very long timeout
  }

  /*---- Endpoints that require longer timeouts Tail ----*/
  

  // RedirectedToLogin but tries to access endpoints that needs authorization
  if (isRedirectedToLogin && requiresToken) {
    return Promise.reject(new Error("[axiosInstance] Request skipped due to user being unauthorized."));
  }

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  // Tries to access endpoints that needs authorization
  if (requiresToken || 
    (authUser && 
      (config.url.includes('/conversations') || config.url.includes('/call-service'))
    )
  ){
    if(authUser){
      const accessToken = authUser.accessToken;
      if (accessToken) {
        //console.log('[axiosInstance] accessToken:', accessToken);
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        console.log('[axiosInstance] Requesting without token failed when calling:', config.url);
      }
    } else {
      console.log('[axiosInstance] authUser not found in localStorage when calling:', config.url);
    }
  }

  // Conversation might happen outside of the platform, so needs special handling
  if(!authUser && (config.url.includes('/conversations') || config.url.includes('/call-service'))){
    const chatbotToken = window.chatbotAuthToken;
    //console.log('chatbotToken:', chatbotToken);
    if (chatbotToken) {
      config.headers.Authorization = `Bearer ${chatbotToken}`;
    } else {
      console.log('[axiosInstance] Requesting without token failed for chatbot conversations');
    }
  }

  
  return config;
}, error => {
  return Promise.reject(error);
});



axiosInstance.interceptors.response.use(
  response => {
    if(response?.config?.url && response.config.url !== '/knovia/refresh-token'){
      console.log(`[axiosInstance.js] Response from ${response.config.url}, received`, response);
    }
    return response;
  },
  async(error) => {
    const originalRequest = error.config;
    console.log('[axiosInstance] error:', error);

    if (error.response) {
      const { status, data } = error.response;
      const errorMessage = data?.message || "An error occurred"; // Get the error message from backend

      //if (!originalRequest._retry && (status === 401 || status === 403) && originalRequest.url !== '/knovia/refresh-token') {
      if (!originalRequest._retry && originalRequest.url !== '/knovia/refresh-token') {
        // Make sure we only retry once with refreshToken()
        originalRequest._retry = true;

        const tokenRefreshed = await refreshToken();
        if (tokenRefreshed) {
          console.log('tokenRefreshed!');
          originalRequest.headers['Authorization'] = `Bearer ${tokenRefreshed}`;
          // Resend request upon successful refresh
          return axiosInstance(originalRequest);
        } else {
          console.log('handleFailedRefresh!');
          handleFailedRefresh();
        }
      }

      console.log()
      return Promise.reject(new Error(errorMessage)); // Pass the error message to the catch block outside
    } else if (error.request) {
      console.error('The request was made but no response was received');
    } else {
      console.error('Error setting up the request:', error.message);
    }
    return Promise.reject(error);
  }
);


function handleFailedRefresh() {
  const now = new Date().getTime();
  const lastAlertTime = sessionStorage.getItem("lastUnauthorizedAlertTime");

  // Redirect the user to the login page when token refresh fails
  if (!sessionStorage.getItem("isRedirectedToLogin")) {
    sessionStorage.setItem("isRedirectedToLogin", "true");
    if (!lastAlertTime || now - lastAlertTime > 5000) { // 5 seconds interval
      sessionStorage.setItem("lastUnauthorizedAlertTime", now.toString());
      // Redirect the user
      window.dispatchEvent(new CustomEvent("customUnauthorizedEvent"));
      console.log('Token refresh failed, returning to login page...');
    }
  }
}


export default axiosInstance;

