import React from 'react';
import Chatbot from './Chatbot';
import './Chatbot.css';

const AgentWrapper = ({ thisAgent, agentImg, customPrompt, token }) => {
  console.log('AgentWrapper props:', { thisAgent, agentImg, customPrompt, token });

  return (
    <div>
      <Chatbot 
      	thisAgent={thisAgent} 
      	agentImg={agentImg} 
      	token={token}
      />
    </div>
  );
};

export default AgentWrapper;